.arForm {
    text-align: right !important;
}

.middlePadding {
    padding-left: 10em;
    padding-right: 10em;
}

.black {
    color: #353535 !important;
}

.gold {
    color: #c9a059 !important;
}

.qSize {
    font-size: 18px;
}

.ansSize {
    font-size: 16px;
}

#more {display: none;}

#more2 {display: none;}

.projectTit {
    color: rgb(255, 255, 255);
    z-Index: 10;
    position: relative;
    padding-top: 10em !important;
    padding-right: 4em !important;
    padding-left: 4em !important;
}

.projectTiten {
    color: rgb(255, 255, 255);
    z-Index: 10;
    position: relative;
    padding-top: 7em !important;
    padding-right: 3em !important;
    padding-left: 3em !important;
}

.projSubT {
    margin-top: -1.5em !important;
    /* color: rgb(210, 205, 205) !important; */
    margin-bottom: 2em;
}

.projectTitB {
    color: rgb(8, 8, 8);
    z-Index: 10;
    position: relative;
    padding-top: 10em !important;
    padding-right: 4em !important;
    padding-left: 4em !important;
}

.projectTitBen {
    color: rgb(8, 8, 8);
    z-Index: 10;
    position: relative;
    padding-top: 7em !important;
    padding-right: 3em !important;
    padding-left: 3em !important;
}

.projSubTB {
    margin-top: -1.5em !important;
    /* color: rgb(58, 58, 58) !important; */
    /* margin-bottom: 2em; */
}

.f7:after {
    background-color: white;
}

.f7 .title {
    color: #353535;
}

.f7 .info-title {
    color: #353535;
}

.lp {
    padding-left: 6em !important;
}

.middlePaddingLess {
    padding-left: 5em !important;
    padding-right: 5em !important;
}

.iar {
    float: right !important;
    padding-left: 2em;
}

.mtm {
    margin-top: -2.5em;
}

.servicesT {
    margin-top: 1em;
    margin-bottom: 2em;
}

.aboutUsT {
    margin-top: 3em;
    margin-bottom: 0.5em;
    text-align: center;
}

.aboutUsButton {
    font-size: 16px;
    width: 50%;
}

.teamMem {
    margin-top: -0.5em;
    margin-bottom: 3.5em;
}

.teamMemA {
    margin-top: -0.5em;
}

.section-testimonials .title {
    color: #353535 !important;
}

.section-testimonials .description {
    color: #353535 !important;
}

.section-testimonials .now-ui-icons {
    color: #353535 !important;
}

.section-testimonials .card-description {
    color: #353535 !important;
}

.section-testimonials .card-title {
    color: #353535 !important;
}

.logo {
    top: -85px !important;
    right: -361px !important;
}

.nav-new {
    font-size: 18px;
}

.map-top {
    margin-bottom: 5em;
}

.no-padd {
    padding: 0 !important;
}
